// Packages
import $ from 'jquery';
import barba from '@barba/core';
import gsap from 'gsap';
import LocomotiveScroll from 'locomotive-scroll';

// Files
const pagetransition = require('./_transition');
const slider = require('./_slider');
const accordion = require('./_accordion');
const navigation = require('./_navigation');
const refreshPlugins = require('./_refreshPlugins');
const fixPageHeight = require('./_fixPageHeight');
const lightbox = require('./_lightbox');
const stats = require('./_stats');
const video = require('./_video');
const list = require('./_list');
const excludeLinks = require('./_exludeLinks');
const memberDirectory = require('./_memberDirectory');

// GSAP config
gsap.config({
	nullTargetWarn: false
});

// Locomotive Scroll initial setup
let scroll;

// create an Observer instance
const resizeObserver = new ResizeObserver(() => {
	scroll.update();
});

// Delay function
function delay(n) {
	return new Promise((done) => {
		setTimeout(() => {
			done();
		}, n);
	});
}

// Barba init & transitions setup
barba.init({
	sync: true,
	prefetchIgnore: true,
	transitions: [{
		async leave() {
			const done = this.async();
			pagetransition.transitionLeave($('[data-barba-namespace]'));
			await delay(1200);
			done();
		},
		async once() {
			pagetransition.transitionFirstLoad($('[data-barba-namespace]'));
		},
		async enter() {
			pagetransition.transitionEnter($('[data-barba-namespace]'));
		}
	}]
});

// Barba Before leave
barba.hooks.before(() => {
	$('html').css('overflow', 'hidden');
	// Destroy current Locomotive Scroll Instance
	resizeObserver.unobserve(document.body);
	scroll.destroy();
	// Site is loading
	$('html').removeClass('is-loaded is-ready');
	$('html').addClass('is-loading');
});

// Barba After enter
barba.hooks.after(() => {
	// Give a small delay before reinit external files / functions
	setTimeout(() => {
		// Create Locomotive Scroll
		scroll = new LocomotiveScroll({
			el: document.querySelector('[data-scroll-container]'),
			smooth: true
		});

		// start observing a DOM node
		resizeObserver.observe(document.body);

		$('html').removeAttr('style');
		// Init Slider
		slider.slidersInit();
		// Init Accordions
		accordion.accordionsInit();
		accordion.accordionFixPageHeight();
		// Init Navigation
		navigation.navigationInit(scroll);
		// init member directory
		memberDirectory.initDirectory();
		// Refresh Plugins
		refreshPlugins.refreshPlugins();
		// Fix Page height
		fixPageHeight.fixPageHeight(scroll);
		// Init Video
		video.videoInit();
		// Lightbox
		lightbox.initLightbox();
		// Stats
		stats.statsInit(scroll);
		// List
		list.listInit(scroll);
		// Exclude link from Barba
		excludeLinks.excludeLinks();
	}, 450);

	setTimeout(() => {
		$('html').addClass('is-loaded is-ready');
		$('html').removeClass('is-loading');
	}, 475);
});

// App initial setup
const App = {
	// App.init
	init() {
		// Give a small delay before reinit external files / functions
		setTimeout(() => {
			// Create Locomotive Scroll
			scroll = new LocomotiveScroll({
				el: document.querySelector('[data-scroll-container]'),
				smooth: true
			});

			// start observing a DOM node
			resizeObserver.observe(document.body);

			$('html').removeAttr('style');
			// Init Slider
			slider.slidersInit();
			// Init Accordions
			accordion.accordionsInit();
			accordion.accordionFixPageHeight();
			// Init Navigation
			navigation.navigationInit(scroll);
			// Fix Page height
			fixPageHeight.fixPageHeight(scroll);
			// Init Video
			video.videoInit();
			// Lightbox
			lightbox.initLightbox();
			// Stats
			stats.statsInit(scroll);
			// List
			list.listInit(scroll);
			// Exclude link from Barba
			excludeLinks.excludeLinks();
			// init member directory
			memberDirectory.initDirectory();
		}, 450);

		// Remove loading classes [Site is ready]
		setTimeout(() => {
			$('html').addClass('is-loaded is-ready');
			$('html').removeClass('is-loading');
		}, 475);
	}
};

// Website is loaded
window.onload = () => {
	// Theme CSS file
	const $style = document.getElementById('theme-css');
	// Check if CSS is loaded
	if ($style.isLoaded) {
		// Init the app
		App.init();
	} else {
		$style.addEventListener('load', () => {
			// Init the app
			App.init();
		});
	}
};
