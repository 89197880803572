// Packages
import $ from 'jquery';
import gsap from 'gsap';

// Init sliders
function navigationInit(scrollInst) {
	// Search
	$('.c-header_nav_search-button').on('click', () => {
		if (!$('.c-header_search').hasClass('is-active')) {
			$('.c-header_search .c-form_input').focus();
		}
		// $(event.currentTarget).toggleClass('is-active');
		$('.c-header_search').toggleClass('is-active');
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Main reset
	$('main').on('click', () => {
		$('.c-header_search').removeClass('is-active');
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Toggler
	$('.c-header_nav_toggler').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.c-mobile_nav').toggleClass('is-active');
		$('.c-header_nav_menu__wrap').toggleClass('is-active');
	});
	// Mini cart
	$('.c-header_nav_cart-button').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.o-mini-cart').toggleClass('is-active');
		$('.c-header_search').removeClass('is-active');
	});
	$('body').on('click', '.o-mini-cart .close', () => {
		$('.o-mini-cart, .c-header_nav_cart-button').removeClass('is-active');
	});
	// Prevent page click in quick menu
	$('#quick-menu .menu-item-has-children .c-header_nav_link').on('click', (event) => {
		event.preventDefault();
		event.stopPropagation();
	});
	// Full screen navigation
	$('#main-menu .menu-item-has-children .c-header_nav_link').on('click', (event) => {
		$('#main-menu .menu-item-has-children .c-header_nav_link').not(event.currentTarget).parent().removeClass('is-active');
		event.preventDefault();
		event.stopPropagation();
		$(event.currentTarget).parent().toggleClass('is-active');
		if ($(event.currentTarget).parent().hasClass('is-active')) {
			gsap.to($('#main-menu .menu-item-has-children'), {
				height: $('#main-menu .c-nav_item .c-header_nav_link').height() + 7,
				duration: 0.5,
				ease: 'power2.out'
			});
			gsap.to($(event.currentTarget).parent(), {
				height: 'auto',
				duration: 0.5,
				ease: 'power2.out'
			});
		} else {
			gsap.to($(event.currentTarget).parent(), {
				height: $('#main-menu .c-nav_item .c-header_nav_link').height() + 7,
				duration: 0.5,
				ease: 'power2.out'
			});
		}
	});
	$(window).on('resize', () => {
		$('#main-menu .menu-item-has-children:not(.is-active) .c-header_nav_link').parent().removeClass('is-active');
		gsap.to($('#main-menu .menu-item-has-children:not(.is-active)'), {
			height: $('#main-menu .c-nav_item .c-header_nav_link').height() + 7,
			duration: 0.5,
			ease: 'power2.out'
		});
	});
	let scrollOffset;
	if ($(window).width() <= 1000) 	{
		// change functionality for smaller screens
		scrollOffset = -68;
	} else {
		// change functionality for larger screens
		scrollOffset = -98;
	}
	// Scroll to next section
	$('.o-scroll-to-next-section').on('click', (event) => {
		const test = $(event.currentTarget).parent().parent().parent()
			.parent()
			.next()
			.prop('className');
		scrollInst.scrollTo(`.${test}`, {
			offset: scrollOffset,
			duration: 500,
			easing: [0.21, 0.61, 0.35, 1.00]
		});
	});
	// Anchor links on same page
	$('a[href*="#"]').on('click', function scrollToAnchorLink() {
		const href = $(this).attr('href');
		const hash = href.substr(href.indexOf('#'));
		scrollInst.scrollTo(hash, {
			offset: scrollOffset,
			duration: 500,
			easing: [0.21, 0.61, 0.35, 1.00]
		});
	});

	// Radisson Navigation
	// Toggler
	$('.c-header_nav_toggler_radisson').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.c-mobile_nav_radisson').toggleClass('is-active');
		$('.c-header_nav_menu__wrap').toggleClass('is-active');
	});

	$('.c-mobile_nav_radisson .c-header_nav_link').on('click', () => {
		$('.c-header_nav_toggler_radisson').removeClass('is-active');
		$('.c-mobile_nav_radisson').removeClass('is-active');
		$('.c-header_nav_menu__wrap').removeClass('is-active');
	});

	// Degustation des vins Navigation
	// Toggler
	$('.c-header_nav_toggler_degustation-vins').on('click', (event) => {
		$(event.currentTarget).toggleClass('is-active');
		$('.c-mobile_nav_degustation-vins').toggleClass('is-active');
		$('.c-header_nav_menu__wrap').toggleClass('is-active');
	});

	$('.c-mobile_nav_degustation-vins .c-header_nav_link').on('click', () => {
		$('.c-header_nav_toggler_degustation-vins').removeClass('is-active');
		$('.c-mobile_nav_degustation-vins').removeClass('is-active');
		$('.c-header_nav_menu__wrap').removeClass('is-active');
	});
}

// Export functions
module.exports = {
	navigationInit
};
