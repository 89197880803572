import $ from 'jquery';

function statsInit(scrollInst) {
	scrollInst.on('call', (value) => {
		if (value === 'startStats') {
			$('.-stats').each(function forAllStats() {
				const $this = $(this);
				const countTo = $this.attr('data-number');
				$({
					countNum: $this.text()
				}).animate({
					countNum: countTo
				}, {
					duration: 2000,
					easing: 'linear',
					step() {
						$this.text(Math.floor(this.countNum).toLocaleString('fr'));
					},
					complete() {
						$this.text((this.countNum).toLocaleString('fr'));
					}
				});
			});
		}

		if (value === 'footerReached') {
			if (!$('.-back-to-home-page').hasClass('-hide')) {
				$('.-back-to-home-page').addClass('-hide');
			} else {
				$('.-back-to-home-page').removeClass('-hide');
			}
		}
	});
}

// Export functions
module.exports = {
	statsInit
};
