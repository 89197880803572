// Packages
import $ from 'jquery';
import List from 'list.js';

// Init sliders
function listInit(scrollInst) {
	const options = {
		listClass: '-results',
		valueNames: ['mois', 'titre', 'nom_membre', 'nom_entreprise', 'secteur']
	};

	const listing = new List('listing', options);

	// Hide no results
	$('.o-list_no-result').hide();

	listing.on('updated', (list) => {
		if (list.matchingItems.length > 0) {
			$('.o-list_no-result').hide();
		} else {
			$('.o-list_no-result').show();
		}
	});

	$('.o-list_pagination__wrap').on('click', '.link', () => {
		scrollInst.scrollTo('.o-section-store-locator', {
			offset: -80,
			duration: 250
		});
	});

	$('#select').change(function selectMonth() {
		const selection = this.value;
		if (selection !== 'All') {
			listing.filter((item) => (item.values().mois === selection));
		} else {
			listing.filter();
		}
	});

	$('#select-2').change(function selectSecteur() {
        const selection = this.value;
        if (selection !== 'All') {
			listing.filter((item) => (item.values().secteur === selection));
		} else {
			listing.filter();
		}
    });
}

// Export functions
module.exports = {
	listInit
};