// Packages
import $ from 'jquery';

function initLightbox() {
	$('.o-section_candidacy .-link, .o-section-bloc_finalistes .-link').each(function buttonPopup() {
		const content = $(this).find('.-popup-content').html();
		$(this).on('click', (event) => {
			event.preventDefault();
			$('.c-popup').addClass('is-active');
			$('.c-popup_content').prepend(content);
			setTimeout(() => {
				$('.c-popup_content').find('.-video').each(function injectVideos() {
					$(this).html($(this).data('video'));
				});
			}, 600);
		});
		$('.c-popup-close').on('click', () => {
			$('.c-popup').removeClass('is-active');
			setTimeout(() => {
				$('.c-popup_content').empty();
			}, 600);
		});
	});
}

// Export functions
module.exports = {
	initLightbox
};
