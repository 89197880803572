// Packages
import $ from 'jquery';

// Refresh Wordpress Plugins
function fixPageHeight() {
	// Generate input number styling
	function generateInputNumber() {
		if ($('.quantity').length) {
			$('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
			$('.quantity').each(function setupNumbersStyling() {
				const spinner = $(this);
				const input = spinner.find('input[type="number"]');
				const btnUp = spinner.find('.quantity-up');
				const btnDown = spinner.find('.quantity-down');
				const min = input.attr('min');
				const max = input.attr('max');

				btnUp.on('click', () => {
					const oldValue = parseFloat(input.val());
					let newVal;
					if (oldValue >= max && !max === null) {
						newVal = oldValue;
					} else {
						newVal = oldValue + 1;
						$('.button-update-cart button').prop('disabled', false);
					}
					spinner.find('input').val(newVal);
					spinner.find('input').trigger('change');
				});

				btnDown.on('click', () => {
					const oldValue = parseFloat(input.val());
					let newVal;
					if (oldValue <= min) {
						newVal = oldValue;
					} else {
						newVal = oldValue - 1;
						$('.button-update-cart button').prop('disabled', false);
					}
					spinner.find('input').val(newVal);
					spinner.find('input').trigger('change');
				});
			});
		}
	}

	generateInputNumber();
	// On Woocommerce Single product infos tabs click
	// $('.woocommerce div.product .woocommerce-tabs ul.tabs li a').on('click', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 100);
	// });
	// // On Woocommerce Single product gallery click
	// $('.woocommerce div.product div.images .flex-control-thumbs li img').on('click', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 600);
	// });
	// $('html').on('click', 'button', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 100);
	// });
	// $('.shipping-calculator-button').on('click', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 1000);
	// });
	// $('#ship-to-different-address-checkbox').on('click', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 1000);
	// });
	// $('.woocommerce').on('change', 'input[type=radio]', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 1000);
	// });
	// scrollInst.on('scroll', () => {
	// 	if ($('.select2-container--open').length) {
	// 		const position = $('.select2-container--open').first().offset().top - $(window).scrollTop();
	// 		$('.select2-container--open').last().css({
	// 			top: position + 40
	// 		});
	// 		$('.select2-container--open .select2-dropdown')
	// .last().removeClass('select2-dropdown--above');
	// 		$('.select2-container--open .select2-dropdown').last().addClass('select2-dropdown--below');
	// 	}
	// });

	// Fix page height on forms
	// $('.quform').on('click', '.quform-button-submit-default>button', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 2000);
	// });
}

// Export functions
module.exports = {
	fixPageHeight
};
