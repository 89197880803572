// Packages
import $ from 'jquery';

// Init sliders
function excludeLinks() {
	const links = ['a[href*="boutique"]', 'a[href*="produit"]', 'a[href*="panier"]', 'a[href*="commander"]', 'a[href*="remove"]', 'a[href*="compte"]', 'a[href*="uploads"]', 'a[href*="les-soins"]', 'a[href*="adhesion"]'];
	// Woocommerce link data
	$(links).each(function preventBarbaOnWoocommerce() {
		// const link = $(this).attr('href');
		$(this).attr('data-barba-prevent', 'self');
	});
}

module.exports = {
	excludeLinks
};
