// Packages
import Accordion from 'accordion-js';
// import $ from 'jquery';

// Init sliders
function accordionsInit() {
	// Accordion JS
	const accordions = Array.from(document.querySelectorAll('.o-accordion-container'));

	if (accordions) {
		let count = 0;
		let id = null;

		accordions.forEach((item) => {
			id = `accordion${count}`;
			item.id = id;
			// eslint-disable-next-line no-unused-vars
			const accordion = new Accordion(`#${id}`, {
				duration: 300,
				showItem: false,
				// onToggle: function (currentElement, allElements) {
				// 	console.log(currentElement);
				// },
				elementClass: 'o-accordion',
				triggerClass: 'o-accordion-question__wrap',
				panelClass: 'o-accordion-answer'
			});
			count += 1;
		});

		// Potentially fix some Locomotive Scroll madness
		window.dispatchEvent(new Event('resize'));
	}
}

function accordionFixPageHeight() {
	// Fix page height after oppening an according
	// $('.o-accordion-question__wrap').on('click', () => {
	// 	setTimeout(() => {
	// 		window.dispatchEvent(new Event('resize'));
	// 	}, 301);
	// });
}

// Export functions
module.exports = {
	accordionsInit,
	accordionFixPageHeight
};
