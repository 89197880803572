import $ from 'jquery';
// Do not remove that line, if removed, select2 will not work.
import select2 from 'select2';

const resetUrl = () => {
	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('secteur')) {
		urlParams.delete('secteur');
		const newUrl = `${window.location.origin}${window.location.pathname}`;
		window.history.replaceState(null, null, newUrl);
	}
};

const filterAndDisplayTiles = (input) => {
	const tiles = document.querySelectorAll('[data-sector]');
	const isAll = input === 'All';
	const maxDisplayedMembers = document.querySelector('#directory-max-members');

	tiles.forEach((tile, index) => {
		const sector = tile.dataset.sector;
		const text = tile.textContent.toLowerCase().trim();
		tile.style.display = isAll || sector === input ? (isAll ? (index < maxDisplayedMembers.value ? 'block' : 'none') : 'block') : text.includes(input) ? 'block' : 'none';
	});
};

const resetSearchInput = (searchInput) => {
	searchInput.value = '';
};

const initDirectory = () => {
	const $select = $('#select-3');
	const searchInput = document.querySelector('.sector-search');
	const urlParams = new URLSearchParams(window.location.search);

	if (urlParams.has('secteur')) {
		const sector = urlParams.get('secteur');
		filterAndDisplayTiles(sector);
	}

	if (searchInput) {
		searchInput.addEventListener('input', () => {
			const filterValue = searchInput.value.toLowerCase().trim();
			filterAndDisplayTiles(filterValue.length > 0 ? filterValue : 'All');
		});
	}

	if ($select) {
		$select.select2({
			dropdownParent: $select.parent(),
			language: {
				noResults: () => "Aucun secteur ne correspond à votre recherche"
			},
			escapeMarkup: (markup) => markup
		});

		$select.on('select2:select', (e) => {
			const { id } = e.params.data;
			resetUrl();
			resetSearchInput(searchInput);
			filterAndDisplayTiles(id);
		});
	}
};
// Export functions
module.exports = {
	initDirectory
};
