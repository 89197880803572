// Packages
// import $ from 'jquery';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

// ---- Register GSAP plugins ----
gsap.registerPlugin(SplitText);

// First load transition
function transitionFirstLoad() {
	// Intro
	// if ($('.o-section_intro').length) {
	// 	const introTl = gsap.timeline({ ease: 'expo.out' });
	// 	introTl.fromTo('.o-section_intro h1', {
	// 		y: 20,
	// 		autoAlpha: 0,
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6
	// 	}, 0.9);
	// 	introTl.to('.o-section_intro .c-bg-color', {
	// 		scaleX: 0,
	// 		transformOrigin: 'right center',
	// 		duration: 1.2,
	// 		ease: 'expo.inOut'
	// 	}, 0.9);
	// 	introTl.fromTo('.o-section_intro h2, .o-section_intro p', {
	// 		y: 20,
	// 		autoAlpha: 0,
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6,
	// 		stagger: 0.1
	// 	}, '-=0.6');
	// 	introTl.fromTo('.o-section_intro .o-scroll-to-next-section', {
	// 		scale: 0,
	// 		autoAlpha: 0,
	// 	}, {
	// 		scale: 1,
	// 		autoAlpha: 1,
	// 		duration: 0.3
	// 	}, '-=0.3');
	// 	if ($('.o-section_slider').length) {
	// 		introTl.fromTo('.o-section_slider', {
	// 			y: 20,
	// 			autoAlpha: 0,
	// 		}, {
	// 			y: 0,
	// 			autoAlpha: 1,
	// 			duration: 0.6
	// 		}, '-=0.15');
	// 	}
	// }
	// Page header
	// if ($('.o-section_page-header').length) {
	// 	const headerTl = gsap.timeline({ ease: 'expo.out' });
	// 	// Headers
	// 	headerTl.fromTo('.o-section_page-header .c-bg-img', {
	// 		scale: 1.25
	// 	}, {
	// 		scale: 1,
	// 		duration: 1.2
	// 	}, 0.3);
	// 	headerTl.fromTo('.o-section_page-header .o-text', {
	// 		y: 20,
	// 		autoAlpha: 0
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6
	// 	}, '-=0.6');
	// 	if ($('.o-section_simple-text').length) {
	// 		headerTl.fromTo('.o-section_simple-text', {
	// 			y: 20,
	// 			autoAlpha: 0,
	// 		}, {
	// 			y: 0,
	// 			autoAlpha: 1,
	// 			duration: 0.6
	// 		}, '-=0.15');
	// 	}
	// }
}

// Leave transition
function transitionLeave() {
	// HTML & Body
	// gsap.set($('body, html, [data-load-container]'), {
	// 	overflow: 'hidden'
	// });
	// Global transition
	// gsap.to('main', {
	// 	y: -20,
	// 	autoAlpha: 0,
	// 	duration: 0.3,
	// 	ease: 'power2.in'
	// });
}

// Enter transition
function transitionEnter() {
	// Intro
	// if ($('.o-section_intro').length) {
	// 	const introTl = gsap.timeline({ ease: 'expo.out' });
	// 	introTl.fromTo('.o-section_intro h1', {
	// 		y: 20,
	// 		autoAlpha: 0,
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6
	// 	}, 0.9);
	// 	introTl.to('.o-section_intro .c-bg-color', {
	// 		scaleX: 0,
	// 		transformOrigin: 'right center',
	// 		duration: 1.2,
	// 		ease: 'expo.inOut'
	// 	}, 0.9);
	// 	introTl.fromTo('.o-section_intro h2, .o-section_intro p', {
	// 		y: 20,
	// 		autoAlpha: 0,
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6,
	// 		stagger: 0.1
	// 	}, '-=0.6');
	// 	introTl.fromTo('.o-section_intro .o-scroll-to-next-section', {
	// 		scale: 0,
	// 		autoAlpha: 0,
	// 	}, {
	// 		scale: 1,
	// 		autoAlpha: 1,
	// 		duration: 0.3
	// 	}, '-=0.3');
	// 	if ($('.o-section_slider').length) {
	// 		introTl.fromTo('.o-section_slider', {
	// 			y: 20,
	// 			autoAlpha: 0,
	// 		}, {
	// 			y: 0,
	// 			autoAlpha: 1,
	// 			duration: 0.6
	// 		}, '-=0.15');
	// 	}
	// }
	// Page header
	// if ($('.o-section_page-header').length) {
	// 	const headerTl = gsap.timeline({ ease: 'expo.out' });
	// 	// Headers
	// 	headerTl.fromTo('.o-section_page-header .c-bg-img', {
	// 		scale: 1.25
	// 	}, {
	// 		scale: 1,
	// 		duration: 1.2
	// 	}, 0.3);
	// 	headerTl.fromTo('.o-section_page-header .o-text', {
	// 		y: 20,
	// 		autoAlpha: 0
	// 	}, {
	// 		y: 0,
	// 		autoAlpha: 1,
	// 		duration: 0.6
	// 	}, '-=0.6');
	// 	if ($('.o-section_simple-text').length) {
	// 		headerTl.fromTo('.o-section_simple-text', {
	// 			y: 20,
	// 			autoAlpha: 0,
	// 		}, {
	// 			y: 0,
	// 			autoAlpha: 1,
	// 			duration: 0.6
	// 		}, '-=0.15');
	// 	}
	// }
}

// Export functions
module.exports = {
	transitionFirstLoad,
	transitionLeave,
	transitionEnter
};
