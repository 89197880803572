// Packages
import $ from 'jquery';
import Swiper, {
	Navigation,
	Pagination,
	Scrollbar,
	Parallax,
	Autoplay
} from 'swiper/core';

// Swiper config
Swiper.use([Navigation, Pagination, Scrollbar, Parallax, Autoplay]);

// Init sliders
function slidersInit() {
	// Slider Intro
	if ($('.swiper-container_intro').length) {
		const swiper = new Swiper('.swiper-container_intro', {
			grabCursor: true,
			parallax: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
			},
			speed: 850,
			loop: true,
			loopAdditionalSlides: 4,
			pagination: {
				el: '.swiper-container_intro .swiper-pagination'
			},
			navigation: {
				nextEl: '.swiper-container_intro .swiper-button-next',
				prevEl: '.swiper-container_intro .swiper-button-prev',
			}
		});
		swiper.init();
	}


	// Slider Main
	if ($('.swiper-container_main-slider').length) {
		const swiper = new Swiper('.swiper-container_main-slider', {
			grabCursor: true,
			parallax: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			speed: 850,
			loop: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		});
		swiper.init();
	}

	// Slider News
	if ($('.swiper-container_news').length) {
		const swiper = new Swiper('.swiper-container_news', {
			grabCursor: true,
			speed: 850,
			navigation: {
				nextEl: '.o-section_news .swiper-button-next',
				prevEl: '.o-section_news .swiper-button-prev',
			},
			slidesPerView: 1,
			spaceBetween: 10,
			breakpoints: {
				// when window width is >= 320px
				300: {
					slidesPerView: 1,
					spaceBetween: 10
				},
				// when window width is >= 320px
				500: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				// when window width is >= 320px
				700: {
					slidesPerView: 2,
					spaceBetween: 30
				},
				// when window width is >= 480px
				1000: {
					slidesPerView: 3,
					spaceBetween: 40
				},
				// when window width is >= 640px
				1200: {
					slidesPerView: 3,
					spaceBetween: 50
				},
				// when window width is >= 640px
				1400: {
					slidesPerView: 3,
					spaceBetween: 60
				},
				1600: {
					slidesPerView: 3,
					spaceBetween: 70
				},
				1800: {
					slidesPerView: 3,
					spaceBetween: 80
				}
			}
		});
		swiper.init();
	}

	// Slider Testimonials
	if ($('.swiper-container_testimonials').length) {
		const swiperTestimonials = new Swiper('.swiper-container_testimonials', {
			grabCursor: true,
			parallax: true,
			speed: 850,
			loop: true,
			loopAdditionalSlides: 4,
			navigation: {
				nextEl: '.o-section_testimonials .swiper-button-next',
				prevEl: '.o-section_testimonials .swiper-button-prev',
			},
		});
		swiperTestimonials.init();
	}

	// Slider timeline
	if ($('.o-section_timeline').length) {
		const swiperTimeline = new Swiper('.o-section_timeline .swiper', {
			grabCursor: true,
			speed: 850,
			slidesPerView: 6,
			// And if we need scrollbar
			scrollbar: {
				el: '.o-section_timeline .swiper-scrollbar',
				draggable: true
			},
			breakpoints: {
				// when window width is >= 320px
				300: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				// when window width is >= 320px
				500: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				// when window width is >= 320px
				700: {
					slidesPerView: 3,
					spaceBetween: 30
				},
				// when window width is >= 480px
				1000: {
					slidesPerView: 4,
					spaceBetween: 30
				},
				// when window width is >= 640px
				1200: {
					slidesPerView: 5,
					spaceBetween: 40
				},
				// when window width is >= 640px
				1400: {
					slidesPerView: 5,
					spaceBetween: 40
				},
				1600: {
					slidesPerView: 6,
					spaceBetween: 60
				},
				1800: {
					slidesPerView: 6,
					spaceBetween: 60
				}
			}
		});
		swiperTimeline.init();
	}

	// Slider Cahier
	if ($('.o-section_candidacy').length) {
		const swiper = new Swiper('.swiper-container_candidacy', {
			grabCursor: true,
			speed: 850,
			navigation: {
				nextEl: '.o-section_candidacy .swiper-button-next',
				prevEl: '.o-section_candidacy .swiper-button-prev',
			},
			breakpoints: {
				// when window width is >= 320px
				300: {
					slidesPerView: 1,
					spaceBetween: 20
				},
				// when window width is >= 320px
				500: {
					slidesPerView: 2,
					spaceBetween: 40
				},
				// when window width is >= 320px
				700: {
					slidesPerView: 2,
					spaceBetween: 40
				},
				// when window width is >= 480px
				1000: {
					slidesPerView: 3,
					spaceBetween: 80
				},
				// when window width is >= 640px
				1200: {
					slidesPerView: 3,
					spaceBetween: 80
				}
			}
		});
		swiper.init();
	}
}

// Export functions
module.exports = {
	slidersInit
};