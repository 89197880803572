import $ from 'jquery';

function videoInit() {
	$('.c-button_play').each(function buttonVideo() {
		const iframe = $(this).data('video');
		$(this).on('click', () => {
			$('.c-video-popup').addClass('is-active');
			setTimeout(() => {
				$('.c-video-popup_video').prepend(iframe);
			}, 600);
		});

		$('.c-video-popup-close, .c-video-popup .c-bg-color').on('click', () => {
			$('.c-video-popup').removeClass('is-active');
			setTimeout(() => {
				$('.c-video-popup_video').empty();
			}, 600);
		});
	});
}

module.exports = {
	videoInit
};
